<template>
  <DashboardSection
    class="wallet-balance-section"
    :title="locale.title"
  >
    <template v-if="wallet.bootstrapped">
      <div class="wallet-balance-section__setup">
        <ElButton
          class="wallet-balance-section__setup-button"
          type="primary"
          @click="openForm"
        >
          {{ locale.setUp }}
        </ElButton>
        <div class="wallet-balance-section__setup-statuses">
          <div class="wallet-balance-section__setup-status">
            <span class="text-s-regular">{{ locale.statuses.yourStatus }}</span>
            <ElTag
              :type="payable ? 'success' : 'warning'"
              size="mini"
            >
              {{ payable ? locale.statuses.payable : locale.statuses.notPayable }}
            </ElTag>
          </div>
          <div class="wallet-balance-section__setup-status">
            <span class="text-s-regular">{{ locale.statuses.paymentMethod }}</span>
            <ElTag
              :type="paymentMethod ? 'primary' : 'warning'"
              size="mini"
            >
              {{ paymentMethod ? paymentMethod : locale.statuses.notAdd }}
            </ElTag>
          </div>
        </div>
      </div>

      <!-- <WalletServicesAdvice v-if="showAdvice" /> -->

      <p class="wallet-balance-section__link">
        <TextLink
          :href="$t('links.learnMoreAboutComissions')"
          target="_blank"
        >
          {{ locale.learnMore }}
        </TextLink>
      </p>
    </template>

    <template v-else>
      {{ locale.loading }} ..
    </template>

    <WalletRazorPayForm :visible.sync="razorPayFormVisible" />
  </DashboardSection>
</template>

<script setup>
import TextLink from 'components/buttons/TextLink'
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import ElButton from 'element/ElButton'
import ElTag from 'element/ElTag'
import { useLocale } from 'lib/helpers/useLocale'
import WalletRazorPayForm from 'Streamer/pages/StreamerWallet/components/WalletRazorPayForm'
import { computed, ref } from 'vue'

import { vm } from '@/main'

const razorPayFormVisible = ref(false)
const locale = computed(() => useLocale('paymentServices.razorPay'))
const wallet = computed(() => vm.$store.getters['streamer/wallet'])
const payoutMethod = computed(() => vm.$store.getters['streamer/payoutMethod'])
const paymentMethod = computed(() => payoutMethod.value.payload.payout_mode)
const payable = computed(() => {
  const { payload } = payoutMethod.value
  if (paymentMethod.value === 'UPI') {
    return payload.account_vpa &&
          payload.full_name &&
          payload.birthday &&
          payload.phone &&
          payload.email &&
          payload.address &&
          payload.number_identification &&
          payload.payout_mode
  }
  return payload.bank_ifsc &&
        payload.beneficiary_account_number &&
        payload.full_name &&
        payload.birthday &&
        payload.phone &&
        payload.email &&
        payload.address &&
        payload.number_identification &&
        payload.payout_mode
})

const openForm = () => {
  razorPayFormVisible.value = true
}
</script>

<style lang="scss" scoped>
.wallet-balance-section {
  position: relative;

  ::v-deep {
    .advice {
      position: absolute;
      left: calc(100% + 24px);
      top: 32px;
    }
  }

  &__setup {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;

    @media (max-width: $--tablet-portrait) {
      flex-direction: column-reverse;
    }

    &-button {
      width: 220px;
      margin-right: 20px;

      @media (max-width: $--tablet-portrait) {
        width: 100%;
        margin-right: 0;
        margin-top: 24px;
      }
    }

    &-status {
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      ::v-deep {
        .el-tag {
          margin-left: 8px;
          text-transform: uppercase;
        }
      }
    }
  }

  &__link {
    @media (max-width: $--tablet-portrait) {
      text-align: center;
    }
  }
}
</style>
