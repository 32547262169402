import moment from 'moment'
import { defineStore } from 'pinia'

import { getAnalyticsData } from 'lib/api/modules/streamer/wallet/analytics'
import { IWalletAnalyticsDay } from 'lib/types/wallet-analytics'

const getMonthEdgeDays = (month: Date): [string, string] => {
  const startOfMonth = moment(month).startOf('month').format('YYYY-MM-DD')
  const endOfMonth = moment(month).endOf('month').format('YYYY-MM-DD')
  return [startOfMonth, endOfMonth]
}

interface State {
  isFetchingData: boolean
  month: Date
  data: IWalletAnalyticsDay[]
}

export const useWalletAnalyticsStore = defineStore({
  id: 'walletAnalytics',

  state: (): State => ({
    isFetchingData: false,
    month: new Date(),
    data: [],
  }),

  actions: {
    async fetchData (streamerId: string) {
      this.isFetchingData = true
      const res = await getAnalyticsData(streamerId, ...getMonthEdgeDays(this.month))
      this.data = res.data
      this.isFetchingData = false
    },
  },
})
