import { CallbackFunction, Trigger } from 'lib/validators/consts/types'

import { i18n } from '@/i18n'

export default {
  validator: (rule: any, value: any, callback: CallbackFunction) => {
    const regexp = new RegExp('^[a-zA-Z0-9]+$')
    if (!regexp.test(value)) {
      callback(new Error(i18n.tc('validator.alphanumeric')))
    }
    else {
      callback()
    }
  },
  trigger: [Trigger.Blur],
}
