import { CallbackFunction, Trigger } from 'lib/validators/consts/types'

import { i18n } from '@/i18n'

export default {
  validator: (rule: any, value: any, callback: CallbackFunction) => {
    const regexp = new RegExp('^[0-9]+$')
    if (!regexp.test(value) || value.trim().length !== 20) {
      callback(new Error(i18n.tc('validator.bankAccount')))
    }
    else {
      callback()
    }
  },
  trigger: [Trigger.Blur],
}
