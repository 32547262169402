<template>
  <ElDrawer
    :title="locale.title"
    :before-close="close"
    :visible="visible"
    direction="rtl"
    custom-class="bank-account-sidebar _razorpay"
    ref="drawer"
    size="560px"
  >
    <div class="text-s-regular bank-account-sidebar__subtitle">
      <a
        :href="$t('links.whyDoWeNeedThisData')"
        target="_blank"
      >{{ locale.whyDoWeNeedThisData }}</a>
    </div>

    <ElForm
      ref="form"
      class="bank-account-form"
      :model="model.data"
      :rules="validation"
      :validate-on-rule-change="false"
      @submit.prevent.native="submit"
    >
      <div class="bank-account-form__row">
        <ElFormItem
          prop="full_name"
          :label="$t('placeholder.fullname')"
        >
          <ElInput
            :placeholder="$t('placeholder.fullname')"
            v-model="model.data.full_name"
            v-input-trim
          />
        </ElFormItem>
      </div>

      <div class="bank-account-form__row bank-account-form__row_double">
        <ElFormItem
          prop="birthday"
          :label="$t('placeholder.birthday')"
        >
          <ElDatePicker
            :placeholder="$t('placeholder.selectDate')"
            type="date"
            format="dd.MM.yyyy"
            value-format="dd.MM.yyyy"
            v-model="model.data.birthday"
          />
        </ElFormItem>
        <ElFormItem
          prop="phone"
          :label="$t('placeholder.phoneNumber')"
        >
          <ElInput
            :placeholder="$t('placeholder.phoneNumber')"
            v-model="model.data.phone"
            v-input-trim
          />
        </ElFormItem>
      </div>

      <div class="bank-account-form__row">
        <ElFormItem
          prop="email"
          :label="$t('placeholder.email')"
        >
          <ElInput
            :placeholder="$t('placeholder.email')"
            v-model="model.data.email"
            v-input-trim
          />
        </ElFormItem>
      </div>

      <div class="bank-account-form__row">
        <ElFormItem
          prop="address"
          :label="$t('placeholder.address')"
        >
          <ElInput
            :placeholder="$t('placeholder.enterAddress')"
            v-model="model.data.address"
            v-input-trim
          />
        </ElFormItem>
      </div>

      <div class="bank-account-form__row bank-account-form__row_double">
        <ElFormItem
          prop="number_identification"
          :label="$t('placeholder.passportNumber')"
        >
          <ElInput
            :placeholder="$t('placeholder.enterPassportNumber')"
            v-model="model.data.number_identification"
            v-input-trim
          />
        </ElFormItem>
        <ElFormItem
          prop="pan_card"
          :label="$t('placeholder.panCardNumber')"
        >
          <ElInput
            :placeholder="$t('placeholder.enterPanCardNumber')"
            v-model="model.data.pan_card"
            v-input-trim
          />
        </ElFormItem>
      </div>

      <div class="bank-account-form__row">
        <div class="el-form-item__label">
          {{ $t('placeholder.panCard') }}
        </div>
        <div class="el-form-item__label">
          {{ $t('placeholder.panCardIfUpload') }}
        </div>
        <FileUploader
          :options="{maxSizeMb: 1, accept: 'image'}"
          :existing-file="existingPanCard"
          :upload-url="panCardUploadUrl"
          @file-error="setIsFileError(true)"
          @file-delete="onPanCardFileDelete"
          @file-upload="onPanCardUploadComplete"
          @uploading-start="onPancardUploadStart"
        />
      </div>

      <div class="bank-account-form__sep" />

      <div class="bank-account-form__row">
        <ElFormItem
          prop="payout_mode"
          :label="$t('placeholder.payoutMode')"
        >
          <ElSelect
            :placeholder="$t('placeholder.choosePayoutMode')"
            v-model="model.data.payout_mode"
          >
            <ElOption
              v-for="item in payoutModes"
              :key="item"
              :value="item"
            />
          </ElSelect>
        </ElFormItem>
      </div>

      <div
        v-if="model.data.payout_mode === 'UPI'"
        class="bank-account-form__row"
      >
        <ElFormItem
          prop="account_vpa"
          :label="$t('placeholder.accountVpa')"
        >
          <ElInput
            placeholder="0123456789"
            v-model="model.data.account_vpa"
            v-input-trim
          />
        </ElFormItem>
      </div>

      <div
        v-else
        class="bank-account-form__row bank-account-form__row_double"
      >
        <ElFormItem
          prop="bank_ifsc"
          :label="$t('placeholder.bankIFSC')"
        >
          <ElInput
            placeholder="ABCD0123456"
            v-model="model.data.bank_ifsc"
            v-input-trim
          />
        </ElFormItem>
        <ElFormItem
          prop="beneficiary_account_number"
          :label="$t('placeholder.beneficiaryAccountNumber')"
        >
          <ElInput
            placeholder="0123456"
            v-model="model.data.beneficiary_account_number"
            v-input-trim
          />
        </ElFormItem>
      </div>

      <div
        class="bank-account-form__submit"
      >
        <ElButton
          type="primary"
          native-type="submit"
          :loading="pending"
        >
          {{ $t('button.save') }}
        </ElButton>
      </div>
    </ElForm>
  </ElDrawer>
</template>

<script setup>
import FileUploader from 'components/form-items/FileUploader'
import ElButton from 'element/ElButton'
import ElDatePicker from 'element/ElDatePicker'
import ElDrawer from 'element/ElDrawer'
import ElForm from 'element/ElForm'
import ElFormItem from 'element/ElFormItem'
import ElInput from 'element/ElInput'
import ElOption from 'element/ElOption'
import ElSelect from 'element/ElSelect'
import { Api } from 'lib/api/instance'
import { useLocale } from 'lib/helpers/useLocale'
import alphanumeric from 'lib/validators/alphanumeric'
import required from 'lib/validators/required'
import { computed, reactive, ref, watch } from 'vue'

import { vm } from '@/main'

defineProps({
  visible: VueTypes.bool,
})

const emit = defineEmits(['update:visible'])

const form = ref(null)

const model = reactive({
  data: {
    full_name: '',
    birthday: '',
    phone: '',
    email: '',
    address: '',
    number_identification: '',
    pan_card: '',
    pan_card_doc: '',
    payout_mode: 'IMPS',
    account_vpa: '',
  },
})
const pending = ref(false)
const isFileError = ref(false)
const isFileUploading = ref(false)

const wallet = computed(() => vm.$store.getters['streamer/wallet'])
const payoutMethod = computed(() => vm.$store.getters['streamer/payoutMethod'])
const existingPanCard = computed(() => {
  if (model.data.pan_card_doc) {
    return { basename: vm.$t('placeholder.panCard') }
  }
  else {
    return null
  }
})
const panCardUploadUrl = computed(() => `${vm.$apiURL}aws/upload/config/razorpay/pan_card`)
const payoutModes = computed(() => payoutMethod?.value?.dictionary?.payout_mode)
const locale = computed(() => useLocale('paymentServices.razorPay.form'))
const validation = computed(() => {
  if (model.data.payout_mode === 'UPI') {
    return {
      full_name: required,
      birthday: required,
      phone: required,
      email: required,
      address: required,
      payout_mode: required,
      account_vpa: required,
      pan_card: alphanumeric,
    }
  }
  return {
    full_name: required,
    birthday: required,
    phone: required,
    email: required,
    address: required,
    payout_mode: required,
    bank_ifsc: required,
    beneficiary_account_number: required,
    pan_card: alphanumeric,
  }
})

watch(payoutMethod, (method) => {
  model.data = method.payload
}, { deep: true })

model.data = payoutMethod.value.payload

const close = () => {
  emit('update:visible', false)
}

const setIsFileError = (isError) => {
  isFileError.value = isError
  isFileUploading.value = false
}

const submit = () => {
  form.value.validate(status => {
    if (!status || isFileError.value || isFileUploading.value) return
    save()
  })
}

const save = async () => {
  pending.value = true
  const data = { ...model.data }
  try {
    await Api.post(`streamer/payment/${payoutMethod.value.slug}/save`, data)
    await wallet.value.fetch()
    close()
  }
  finally {
    pending.value = false
  }
}

const onPanCardFileDelete = () => {
  model.data.pan_card_doc = ''
  setIsFileError(false)
}
const onPancardUploadStart = () => {
  setIsFileError(false)
  isFileUploading.value = true
}
const onPanCardUploadComplete = (params) => {
  setIsFileError(false)
  isFileUploading.value = false
  model.data.pan_card_doc = params.attachment?.file?.key
}
</script>

<style lang="scss">
.bank-account-sidebar._razorpay {
  @media(max-width: $--large-mobile) {
    width: 100% !important;
  }
}
</style>

<style lang="scss" scoped>
.bank-account-sidebar {
  &__subtitle {
    margin-top: -32px;
    margin-bottom: 32px;
  }
}

.bank-account-form {
  :deep {

  }

  &__sep {
    border-top: 1px solid $--color-lightest-gray;
    margin-bottom: 22px;
  }

  &__row {
    width: 100%;
    margin-bottom: 24px;

    &_double {
      display: flex;
      justify-content: space-between;

      @media(max-width: $--large-mobile) {
        display: block;
      }

      ::v-deep {
        .el-form-item {
          width: calc(50% - 10px);

          @media(max-width: $--large-mobile) {
            width: 100%;
          }

          .el-input {
            width: 100%;
          }
        }
      }
    }

    ::v-deep {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }

  &__submit {
    margin-top: 40px;

    ::v-deep {
      .el-button {
        width: calc(50% - 10px);

        @media(max-width: $--large-mobile) {
          width: 100%;
        }
      }
    }
  }

  ::v-deep {
    .el-form-item__label:before {
      display: none;
    }
  }
}
</style>
