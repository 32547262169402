<template>
  <WalletSection>
    <DashboardSection
      :title="locale.title"
      :no-border="true"
    >
      <WalletHistoryTable v-if="transactions.bootstrapped" />
      <template v-else>
        {{ locale.loading }} ..
      </template>
      <ElPagination
        layout="prev, pager, next"
        :prev-text="locale.prev"
        :next-text="locale.next"
        :page-size="transactions.perPage"
        :current-page="transactions.page"
        :total="transactions.total"
        hide-on-single-page
        @current-change="changePage"
      />
    </DashboardSection>
  </WalletSection>
</template>

<script setup>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import ElPagination from 'element/ElPagination'
import { useLocale, useRootLocale } from 'lib/helpers/useLocale'
import WalletHistoryTable from 'Streamer/pages/StreamerWallet/components/WalletHistoryTable'
import WalletSection from 'Streamer/pages/StreamerWallet/components/WalletSection'
import { computed } from 'vue'

import { vm } from '@/main'

const locale = computed(() => ({
  title: useLocale('payoutHistory.title'),
  loading: useLocale('payoutHistory.loading'),
  prev: useRootLocale('pagination.prev'),
  next: useRootLocale('pagination.next'),
}))

const transactions = computed(() => vm.$store.getters['streamer/transactions'])

const changePage = (page) => {
  vm.$store.dispatch('campaigns/setTransactionsPage', page)
}
</script>
