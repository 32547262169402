<template>
  <div
    class="wallet-section"
    :class="{'wallet-section-collapsed' : collapsed}"
  >
    <button
      v-show="$isMobile"
      class="wallet-section__toggle"
      :class="{'wallet-section__toggle-no-border': noBorder}"
      @click="collapseSection"
    >
      <SvgIcon
        :name="collapsed? 'collapse-plus' : 'collapse-minus'"
        width="24"
        height="24"
      />
    </button>

    <slot />
  </div>
</template>

<script setup>
import { ref } from 'vue'

defineProps({
  noBorder: VueTypes.boolean,
})

const collapsed = ref(false)
const collapseSection = () => {
  collapsed.value = !collapsed.value
}
</script>

<style lang="scss" scoped>
.wallet-section {
  position: relative;
  margin-bottom: 40px;

  @media (max-width: $--tablet-portrait) {
    /* padding-bottom: 32px; */
    margin-bottom: 32px;
  }

  ::v-deep {
      .dashboard-section {
        padding-bottom: 0;
      }
    }

  &-collapsed {
    ::v-deep {
      .dashboard-section__right {
        display: none;
      }
    }
  }

  &__toggle {
    position: absolute;
    top: 28px;
    right: 0;
    z-index: 5;
    background-color: transparent;
    border: none;

    &-no-border {
      top: -4px;
    }
    .svg-icon {
      fill: #1A1A1A;
    }
  }
}
</style>
