import { Api } from "lib/api";
import { IWalletAnalyticsDay } from "lib/types/wallet-analytics";
import { IResponseData } from "lib/types/response";
import { IdType } from "lib/types/base-types";

export const getAnalyticsData = (
  streamerId: string,
  start: string,
  end: string
) => {
  return Api.get<IResponseData<IWalletAnalyticsDay[]>>(
    `statistic/streamer/${streamerId}/income/${start}/${end}`
  ).then((res) => res);
};
