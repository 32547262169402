import { CallbackFunction, Trigger } from 'lib/validators/consts/types'

import { i18n } from '@/i18n'

export default {
  validator: (rule: any, value: any, callback: CallbackFunction) => {
    if (typeof value === 'number') {
      value = value.toString()
    }
    else if (/[^0-9]/.test(value)) {
      callback(new Error(i18n.tc('validator.bic')))
    }
    else if (value.length !== 9) {
      callback(new Error(i18n.tc('validator.bic')))
    }
    callback()
  },
  trigger: [Trigger.Blur],
}
