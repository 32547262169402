import { ReverseMap } from 'lib/types/base-types'

export enum Campaign {
  BRAND_AWARENESS = 'brand_awareness',
  PERFORMANCE = 'performance',
  YANDEX_FS = 'yandex_fs',
  PREROLL = 'preroll',
  BRAND_LIFT = 'brand_lift',
  TASK = 'task',
}

export interface ICampaignType {
  id: Campaign
  title: string
  description: string
  icon: 'speaker' | 'list' | 'task-checked' | 'dialog-bubbles'
  disabled?: boolean
}

export type CampaignType = ReverseMap<typeof Campaign>
