<template>
  <div class="file-uploader">
    <div class="file-uploader__loader">
      <div
        v-loading="showSpinner"
        class="file-uploader__loader-circle"
      >
        <CircleLoader
          v-if="!showSpinner"
          :percent="current"
        />
      </div>
      <div class="file-uploader__loader-text">
        <div class="text-m-bold file-uploader__loader-loading">
          {{ status }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleLoader from 'components/CircleLoader'
import loading from 'element/Loading'
import Vapor from 'laravel-vapor'
import { Api } from 'lib/api/instance'
import { getCampaignTypeBySlug } from 'lib/helpers/getCampaignTypeBySlug'

export default {
  components: {
    CircleLoader,
  },

  directives: {
    loading,
  },

  props: {
    url: VueTypes.string.isRequired,
    params: VueTypes.object.isRequired,
    uploading: VueTypes.bool,
    hasErrors: VueTypes.bool,
    noValidate: VueTypes.bool,
    customUrl: VueTypes.string,
  },

  data: () => ({
    cancelToken: null,
    percent: 0,
    current: 0,
    processing: false,
  }),

  computed: {
    locale: ({ $rootLocale }) => $rootLocale('other.uploader'),
    isLoaded: ({ percent }) => percent === 100,
    campaignType: ({ $route }) => getCampaignTypeBySlug($route.params.campaignSlug),
    format: ({ $store, campaignType }) => {
      if (campaignType === 'performance') {
        return 'interactive'
      }
      if (campaignType === 'preroll') {
        return 'preroll'
      }
      else {
        return $store.getters['creative/format']
      }
    },
    showSpinner: ({ uploading, processing }) => uploading && processing,
    type: ({ params }) => {
      let type
      for (const key in params) {
        if (/unit|video|zip|admngr/.test(key)) {
          type = key
        }
      }
      return type
    },
    status ({ hasErrors, isLoaded, processing, current, locale }) {
      if (isLoaded && processing) {
        return `${locale.processing} ..`
      }
      if (isLoaded && hasErrors) {
        return locale.declined
      }
      return `${locale.loading} ${current}%`
    },
  },

  mounted () {
    this.upload()
  },

  watch: {
    percent () {
      const interval = setInterval(() => {
        if (this.current < this.percent) {
          this.current += 1
        }
        else {
          clearInterval(interval)
        }
      }, 1)
    },
  },

  methods: {
    async upload () {
      const file = this.params[this.type].value || this.params[this.type]
      const signedStorageUrl = this.customUrl || `${this.$apiURL}aws/upload/config`

      const res = await Vapor.store(file, {
        signedStorageUrl,
        progress: progress => {
          this.percent = Math.round(progress * 100)
        },
      })

      if (!this.noValidate) {
        await this.validate(res.key)
      }

      this.$emit('uploaded', {
        type: this.type,
        file: {
          basename: file.name,
          path: res.url,
          key: res.key,
        },
      })
    },

    async validate (key) {
      this.processing = true
      const data = {
        format: this.format,
        [this.type]: key,
      }
      let url = 'partner/campaigns/brand_awareness/ad/attach/verify'
      if (this.campaignType === 'performance') {
        url = 'partner/campaigns/performance/ad-set/attach/verify'
      }
      if (this.campaignType === 'preroll') {
        url = 'partner/campaigns/preroll/ad-set/attach/verify'
      }
      try {
        await Api.post(
          url,
          data,
          { showMessage: false },
        )
      }
      catch (err) {
        this.catchError(err)
      }
      finally {
        this.processing = false
      }
    },

    catchError (err) {
      if (err.response) {
        // Request made and server responded
        console.log(err.response.data) // eslint-disable-line no-console
        console.log(err.response.status) // eslint-disable-line no-console
        console.log(err.response.headers) // eslint-disable-line no-console
        this.$emit('error', [this.locale.fileUploadError])
      }
      else if (err.request) {
        // The request was made but no response was received
        console.log(err.request) // eslint-disable-line no-console
        this.$emit('error', [this.locale.fileUploadError])
      }
      else if (err.message) {
        this.$emit('error', [err.message])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.file-uploader {
  padding: 20px;
  height: 79px;
  background-color: $--background-color-base;
  border-radius: $--border-radius-base;

  &__loader {
    display: flex;

    &-circle {
      width: 40px;

      ::v-deep {
        .el-loading-mask {
          background-color: transparent;
        }
      }
    }
    &-text {
      padding-top: 2px;
      margin-left: 20px;
    }
    &-loading {
      margin-bottom: 3px;
    }
  }
}
</style>
